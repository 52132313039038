export default () => {
  var swiper = new Swiper('.swiper-container', {
    loop: true,
    slidesPerView: 'auto',
    centeredSlides : true,
    autoResize: false,
    autoplay: {delay: 5000},
    pagination: {
      el: '.swiper-paginations',
      type: 'fraction',
      bulletElement: 'div'
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  });

  //スライド名を他要素に表示
  function currentSlideName(){
    let currnetItemName = $('.swiper-slide.swiper-slide-active').find('.environment__slide-item-name').text();
    $('.environment__slide-bottom-item-name').text(currnetItemName);
  };

  swiper.on('slideChangeTransitionEnd', function () {
    currentSlideName();
  });

  currentSlideName();
};
