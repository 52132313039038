export default () => {
  /**
   * variables
   */
  const $entryJs = $('.entry-component__item__label-sp');
  const isSPMediaQuery = matchMedia('(max-width: 765px)');

  /**
   * methods
   */
  // PCモードの表示の初期化
  const initPC = () => {
    $('.entry-component-list').show();
    $('.entry-component-card__arrow').addClass('-open');
  };

  // SPモードの表示の初期化
  const initSP = () => {
    $('.entry-component-list').hide();
    $('.entry-component-card__arrow').removeClass('-open');
  };

  // メディアの変更を検知した際に走らせる命令
  const matchMediaHandler = (spMatcher) => {
    // sp
    if (spMatcher.matches) {
      initSP();
      return;
    } 

    // pc
    initPC();
    return;
  }

  /**
   * media listeners
   */
  isSPMediaQuery.addListener(matchMediaHandler);

  /**
   * initialized component
   */
  $(() => {
    matchMediaHandler(isSPMediaQuery);

    // ページのイベントリスナー
    $entryJs.on('click', function (){
      $(this).find('.entry-component-card__arrow').toggleClass('-open')
      $(this).next().slideToggle(250);
    });
  }); 
}
