/**
 * 依存スクリプト
 *
 * jQuery 3.4.1
 * Velocity 1.5.2
 * JQuery inView 1.1.2
 */
import 'promise-polyfill';
import 'lazysizes';
// import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import './partials/header.js';
import slide from './partials/slide';
import voice from './partials/voice';
import etnryComponent from './partials/entry-component';
import interview from './partials/interview';
import projectSlide from './partials/project-slide.js';
import imageFadeSlide from './partials/image-fade-slide';
import mainVisual from './partials/main-visual.js';
import teamlabImage from './partials/teamlab-image.js';
import inview from './partials/inview.js';
import employment from './partials/employment.js';
import comment from './partials/comment.js';
import environment from './partials/environment.js';
import i18n from './partials/i18n.js';
import distribution from './partials/distribution.js';

//add simple support for background images:
document.addEventListener('lazybeforeunveil', function(e){
    var bg = e.target.getAttribute('data-srcset');
    if(bg){
        e.target.style.backgroundImage = 'url(' + bg + ')';
    }
});

// 一旦 slickの読み込みタイミングを変えてみる
slide();
mainVisual();
$('#voice-components').ready(voice);
$('#environment').ready(environment);
interview();
projectSlide();
imageFadeSlide();
teamlabImage ();
inview();
i18n();
employment();
$('#comment').ready(comment);
$('#entry-component').ready(etnryComponent());
distribution();
