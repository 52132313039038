export default () => {
  function sliderStart(slideId) {

    const slide = document.getElementById(slideId);               //スライダー親
    const slideItem = slide.querySelectorAll('.js-slide_item');   //スライド要素
    const totalNum = slideItem.length - 1;                        //スライドの枚数を取得
    const fadeTime = 2000;                                        //フェードインの時間
    const intarvalTime = 5000;                                    //クロスフェードさせるまでの間隔
    let actNum = 0;                                               //現在アクティブな番号
    let nowSlide;                                                 //現在表示中のスライド
    let nextSlide;                                                //次に表示するスライド

    // スライドの1枚目をフェードイン
    slideItem[0].classList.add('is-show', 'is-zoom');

    // 処理を繰り返す
    setInterval(() => {
      if (actNum < totalNum) {

        const nowSlide = slideItem[actNum];
        actNum += 1;
        const nextSlide = slideItem[actNum];

        //.is-show削除でフェードアウト
        nowSlide.classList.remove('is-show');
        // と同時に、次のスライドがズームしながらフェードインする
        nextSlide.classList.remove('is-zoomCancel');
        nextSlide.classList.add('is-show', 'is-zoom');
        //フェードアウト完了後、.is-zoom削除
        setTimeout(() => {
          nowSlide.classList.remove('is-zoom');
          nowSlide.classList.add('is-zoomCancel');
        }, fadeTime);

      } else {

        let nowSlide = slideItem[actNum];
        actNum = 0;
        let nextSlide = slideItem[actNum];

        //.is-show削除でフェードアウト
        nowSlide.classList.remove('is-show');
        // と同時に、次のスライドがズームしながらフェードインする
        nextSlide.classList.remove('is-zoomCancel');
        nextSlide.classList.add('is-show', 'is-zoom');
        //フェードアウト完了後、.is-zoom削除
        setTimeout(() => {
          nowSlide.classList.remove('is-zoom');
          nowSlide.classList.add('is-zoomCancel');
        }, fadeTime);
      };
    }, intarvalTime);
  };

  window.addEventListener('load', function () {
    sliderStart('js-mainSlidePc');
    sliderStart('js-mainSlideSp');
  });
};
