export default () => {
  function mainVisualFade() {
    const $body = $('.main-visual');
    const $title = $('.js-main-title');
    const $entryBtn = $('.main-visual__button');
    let fadeTime = 1000;
    setTimeout(function(){
      $body.addClass('is-display');
    },fadeTime);
    setTimeout(function(){
       $title.addClass('is-anime');
    },fadeTime + 600);
    setTimeout(function(){
       $entryBtn.addClass('is-anime');
    },fadeTime + 1200);
  }
  mainVisualFade();
}
