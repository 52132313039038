let judge = true;
export default (event) => {
  $('.distribution__form-button').on( 'click', (e) => {
    judge = true;
    e.preventDefault();
    judgeForm();

    if( judge ) {
      $('#form').submit();
      $('#mailAddress').val('');
      $('.distribution__form-check-box').prop('checked', false);
    }
  });
}

function judgeForm() {
  const pattern = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if ( $('#mailAddress').val() && pattern.test( $('#mailAddress').val() ) ) {
    $( '#mail-text' ).css( 'visibility', 'hidden' );
  } else {
    $( '#mail-text' ).css( 'visibility', 'unset' );
    judge = false;
  }

  if ( $('#policy').is(':checked')) {
    $( '#policy-text' ).css( 'visibility', 'hidden' );
  } else { 
    $( '#policy-text' ).css( 'visibility', 'unset' );
    judge = false;
  }

  const typeCheckboxes = $('.distribution__form-check-box[name="propval_8[]"]:checked');
  if (typeCheckboxes.length !== 0) {
    $( '#type-text' ).css( 'visibility', 'hidden' );
  } else {
    $( '#type-text' ).css( 'visibility', 'unset' );
    judge = false;
  }

  const tagCheckboxes = $('.distribution__form-check-box[name="propval_18[]"]:checked');
  if (tagCheckboxes.length !== 0) {
    $( '#tag-text' ).css( 'visibility', 'hidden' );
  } else {
    $( '#tag-text' ).css( 'visibility', 'unset' );
    judge = false;
  }
}

