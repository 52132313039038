export default () => {
  function projectSLide() {
    let arrayItemNum = [];   //スライドのアイテム数
    let arrayNowItem = [];   //現在のアイテム
    let projectTimer;
    let itemNum = 0;
    //初期アイテムにis-display追加
    $('.project__item-image-list').each((i,element)=>{
      $(element).children('.project__item-image-list-item').eq(0).addClass('is-display');
    });
    $('.project__item').each((i,element)=>{
      itemNum = $(element).find('li').length - 1;
      //各個数を配列に追加
      arrayItemNum.push(itemNum);
      //各要素ごとの変わる時間を買える
      let delayTime = 1500*i;
      arrayNowItem[i] = 0;
      projectTimer = setInterval (() => {
        // i 要素の順番、elements 要素のクラス、time 要素ごとのdelay time
        timeItem(i,element,delayTime);
      },8000);

    });

    function timeItem (i,element,time) {
      if (arrayNowItem[i] >= arrayItemNum[i]) {
        arrayNowItem[i] = 0;
      } else {
        arrayNowItem[i] = arrayNowItem[i] + 1;
      }
      $(element).children('.project__item-image-list').children('.project__item-image-list-item').eq(arrayNowItem[i]).delay(time).queue(()=>{
        $(element).children('.project__item-image-list').children('.project__item-image-list-item').removeClass('is-display').dequeue();
        $(element).children('.project__item-image-list').children('.project__item-image-list-item').eq(arrayNowItem[i]).addClass('is-display').dequeue();
      })
    };
  };

  window.addEventListener('load', function () {
    projectSLide();
  });
}
