function isTB() {
  return matchMedia('(min-width: 768px)').matches;
}
function isPC() {
  return matchMedia('(min-width: 1024px)').matches;
}

function handleClose() {
  const maxHeight = Math.max(...$('.interview__link').map(function() {
    return this.clientHeight;
  }));
  $('.interview__item').each(function (index) {
    if (isTB() && !isPC() && index > 1) {
      this.style.maxHeight = '0px';
      this.style.opacity = '0';
      setTimeout(() => {
        this.style.margin = '0';
      }, 500);
    } else if (index > 2) {
      this.style.maxHeight = '0px';
      this.style.opacity = '0';
      setTimeout(() => {
        this.style.margin = '0';
      }, 500);
    } else {
      this.style.transition = '0ms';
      this.style.maxHeight = `${maxHeight}px`;
      this.style.opacity = '1';
      this.style.margin = '';
      setTimeout(() => {
        this.style.transition = '';
      }, 500);
    }
  });
}

function handleOpen() {
  const maxHeight = Math.max(...$('.interview__link').map(function() {
    return this.clientHeight;
  }));
  $('.interview__item').each(function (index) {
    if (isTB() && !isPC() && index > 1) {
      this.style.maxHeight = `${maxHeight}px`;
      this.style.margin = '';
      this.style.opacity = '1';
    } else if (index > 2) {
      this.style.maxHeight = `${maxHeight}px`;
      this.style.margin = '';
      this.style.opacity = '1';
    }
  });
}

function handleResize() {
  const maxHeight = Math.max(...$('.interview__link').map(function() {
    return this.clientHeight;
  }));

  $('.interview__item').each(function () {
    this.style.height = `${maxHeight}px`
  });
}

function handleToggle(toggle) {
  if (toggle) {
    handleClose();
  } else {
    handleOpen();
  }
}

export default () => {
  let toggle = true;
  $('.interview__button').on('click',function(){
    $(this).toggleClass('is-open');
    toggle = !toggle;
    handleToggle(toggle);
  });

  $('interview').ready(function() {
    handleClose();
    handleResize();
    window.addEventListener('resize', function() {
      handleResize();
      handleToggle(toggle);
    });
  });

};
