export default () => {
  const swiper = new Swiper('.environment-swiper-container', {
    loop: true,
    slidesPerView: 'auto',
    centeredSlides : true,
    autoResize: false,
    autoplay: {
      delay: 5000
    },
    pagination: {
      el: '.swiper-paginations',
      type: 'fraction',
      bulletElement: 'div'
    },
    navigation: {
      nextEl: '.enviroment-caption__nums__next',
      prevEl: '.enviroment-caption__nums__prev',
    }
  });

  //スライド名を他要素に表示
  function currentSlideName() {
    let $activeEl = $('.enviroment-slider-item__image.swiper-slide.swiper-slide-active')
    $('#enviroment-caption-title').text($activeEl.data('name'));
  };

  swiper.on('slideChangeTransitionEnd', function () {
    currentSlideName();
  });

  currentSlideName();
}
