export default () => {
  $('.js-teamlabImagePc,.js-teamlabImageSp').slick({
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    arrows: false,
    autoplaySpeed: 5000,
    autoplay: true
  });
};
