
//スクロールでメニューの色変え
const $header = $('.js-header');

//インタビューページであるかどうか
if ($('#js-interview-page').length) {
  //インタビューページである時は背景色を白で固定する
  $header.addClass('on-scroll').addClass('is-white');
} else {
  $(window).on('scroll' , () => {
    let $scrollNum = $(window).scrollTop();
    if ( $scrollNum > 50 ) {
      $header.addClass('on-scroll').addClass('is-white');
    } else {
      $header.removeClass('on-scroll').removeClass('is-white');
    }
  });
}

//spメニュートグルで開閉
const $menuOpen = $('.js-menu-open');
const $spMenu = $('.js-header-menu');
const $spShade = $('.js-header-shade');
const $menuItems = $('.header__menu-item-slide');
let cancelFlag = 0;
$menuOpen.on('click', () => {
  if(cancelFlag == 0) {
    cancelFlag = 1;
    $menuOpen.toggleClass('is-open');
    $spMenu.toggleClass('is-open');
    $spShade.toggleClass('is-display');
    $menuOpen.toggleClass('is-open');

    if ($spMenu.hasClass('is-open')) {
      $header.addClass('is-spDisplay');
      $menuItems.each((i,elem) => {
        let $this = $(elem);
        setTimeout(() => {
          $this.addClass('is-slide');
        },100*i);
        $(this).addClass('is-slide');
      });

    } else {
      $header.removeClass('is-spDisplay');
      $menuItems.removeClass('is-slide');
    }
    setTimeout(function(){
      cancelFlag = 0;
    },700);
  }
});

//シャドウをクリックでメニュー閉じる
$spShade.on('click' , () => {
  headerSpReset();
});

//SPメニューリセット処理
const headerSpReset = () => {
  $spShade.removeClass('is-display');
  $spShade.removeClass('is-open');
  $spMenu.removeClass('is-open');
  $spShade.removeClass('is-display');
  $header.removeClass('is-spDisplay');
  $menuOpen.removeClass('is-open');
}

//PC時にSPメニューリセット
$(window).on('load resize', function(){
  const winW = $(window).width();
  const devW = 767;
  if (winW <= devW) {
    //767px以下の時の処理
  } else {
    //768pxより大きい時の処理
    headerSpReset();
  }
});

// ヘッダーのスムーススクロールを設定する処理
$('.js-header-link').on('click', (e) => {
  // 発火させた要素を取得してリンク先をとる
  const target = $(e.target).attr('href');
  const $scrollTarget = $(target);

  // スムーススクロール
  $scrollTarget.velocity('scroll', {
    duration: 500,
    easing: 'easeOutCubic',
  });
});

$('.js-header-link').on('click', headerSpReset);

