function changeLang(lang) {
  if(lang === 'ja') {
    location.href = '/';
  } else {
    location.href = `/${lang}`;
  }
}

function setLang() {
  const lang = location.pathname.split(/[/]/)[1];
  if(lang === '') {
    $('#lang').val('ja');
  } else {
    $('#lang').val(lang);
  }
}

function initLanguageMenu() {
  const TOGGLED_CLASS = 'is-visible';
  const $languageMenu = $('.headLangSelect, .headLangSelectSP');
  const $languageList = $('.headLangSelect__list, .headLangSelectSP__list');
  const $toggle = $('.headLangSelect__selected, .headLangSelectSP');
  const handleClick = () => {
    if (!$languageMenu.hasClass(TOGGLED_CLASS)) {
      $languageMenu.addClass(TOGGLED_CLASS);
      $languageList
        .css('transform', 'translate3d(0, 0, 0)')
        .velocity(
          { opacity: 1, translateY: 10 },
          { duration: 400, easing: 'easeOutCubic' }
        );
    } else {
      $languageList.velocity(
        { opacity: 0, translateY: 0 },
        {
          duration: 400,
          easing: 'easeOutCubic',
          complete: () => {
            $languageMenu.removeClass(TOGGLED_CLASS);
          },
        }
      );
    }
  };
  const handleClickWindow = event => {
    if (
      $languageMenu.hasClass(TOGGLED_CLASS) &&
      $(event.target).closest('#langSelect').length === 0
    ) {
      //閉じる
      handleClick();
    }
  };
  //$toggle.removeEventListener('click', handleClick);
  //$(window).removeEventListener('click', handleClickWindow);
  $toggle.on('click', handleClick);
  $(window).on('click', handleClickWindow);
  $('.headLangSelect__item__link').on('click', () => {
    //Cookies.set('language', $(this).data('language'), { expires: 365 * 5 });
  });
  return this;
}

export default () => {
  initLanguageMenu();
  setLang();
};
