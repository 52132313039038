const DEVICE_THRESHOLD = 767;

// voice
let voicePc = null;
let voiceSp = null;

const mediaQuery = matchMedia('(max-width: 765px)');

function handleDeviceChange(mq) {
  if (mq.matches) {
      if (voicePc !== null) {
        voicePc.destroy();
        voicePc = null;
      }
      voiceSp = new VoiceSp();
  } else {
      if (voiceSp !== null) {
        voiceSp.destroy();
        voiceSp = null;
      }
      voicePc = new VoicePc();
  }
}

/**
 * SP表示時の体験者の声スライド
 */
class VoiceSp {
  constructor() {
    this.voices = [];
    this.voices = new Swiper('.js-comment-swiper-container', {
      slidesPerView: 'auto',
      spaceBetween: 10,
      slidesOffsetBefore: 15,
      slidesOffsetAfter: 15,
      autoResize: false,
      speed: 400,
      autoplay: false,
      loop: false,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    });
  }

  destroy() {
    this.voices.destroy();
  }
}

/**
 * PC表示時の体験者の声スライド
 */
class VoicePc {
  constructor() {
    this.voices = [];
    this.voices = new Swiper('.js-comment-swiper-container', {
      slidesPerView: 'auto',
      spaceBetween: 10,
      slidesOffsetBefore: 15,
      slidesOffsetAfter: 15,
      autoResize: false,
      speed: 400,
      autoplay: false,
      loop: false,
      mousewheel: {
        invert: true,
        forceToAxis: true
      },
      freeMode: true
    });
  }

  destroy() {
    this.voices.destroy();
  }
}

handleDeviceChange(mediaQuery);

