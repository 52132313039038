export default () => {
  /* attributes */
  let swiper;
  const mediaQuery = matchMedia('(max-width: 765px)');
  const slideLength = document.querySelectorAll('.employment-list .employment-list__content').length;

  /* methods */
  function handle(mq) {
    if (mq.matches) {
      if (swiper) {
        swiper.destroy();
        swiper = undefined;
      }
    } else {
      if (swiper) {
        return;
      } else {
        if (slideLength > 2) {
          swiper = new Swiper('.employment__scroll-wrapper', {
            slidesPerView: 'auto',
            spaceBetween: 30,
            autoResize: false,
            slidesOffsetBefore: 100,
            slidesOffsetAfter: 100,
            speed: 200,
            mousewheel: {
              invert: true,
              forceToAxis: true
            },
            freeMode: true,
            pagination: {
              el: '.swiper-pagination',
              type: 'bullets',
              clickable: true
            }
          });
        } else {
          const swiperElm = document.querySelector('.employment__scroll-wrapper');
          swiperElm.classList.add('swipe-off');
        }
      }
    }
  }

  /* handlers */
  // 初期化処理
  handle(mediaQuery);

  // Resize検知
  mediaQuery.addListener(handle);
}
